<template>
  <base-material-card
    icon="mdi-account-multiple-plus"
    color="secondary"
    :title="title"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filterOptions.search"
          label="Search"
          append-icon="mdi-magnify"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="filterOptions.temperature"
          :items="mixinItems.temperatureTypes"
          item-value="id"
          item-text="name"
          prepend-icon="mdi-thermometer-alert"
          label="Temperature"
          clearable
          :loading="loadingMixins.temperatureTypes"
        />
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-data-table
      :headers="headers"
      :items="leads"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td>
              <router-link
                :to="`/leads/${item.user}-${item.id}`"
              >
                {{ item.name | truncate(42) }}
              </router-link>
            </td>
            <td>{{ item.priority_type }}</td>
            <td>{{ item.status_type }}</td>
            <td>{{ item.temperature_type }}</td>
            <td>{{ item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD') : '' }}</td>
            <td>
              <v-chip
                :color="userOrCompany(item.user).color"
                label
              >
                {{ userOrCompany(item.user).text }}
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <table-footer
      :options="options"
      :total="total"
    />
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    components: {
      TableFooter: () => import('../components/TableFooter'),
    },

    mixins: [
      fetchInitials([
        MIXINS.temperatureTypes,
      ]),
    ],

    props: {
      title: {
        type: String,
        default: 'Leads',
      },
      url: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      loading: false,
      moment,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Priority', value: 'priority_type' },
        { text: 'Status', value: 'status_type' },
        { text: 'Temperature', value: 'temperature_type' },
        { text: 'Updated At', value: 'updated_at' },
        { text: 'Type', value: 'user', sortable: false },
      ],
      leads: [],
      options: {},
      total: 0,
      timeout: null,
      filterOptions: {},
    }),

    watch: {
      options: {
        handler () {
          this.getLeads()
        },
        deep: true,
      },
      filterOptions: {
        handler () {
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.options.page = 1
            this.getLeads()
          }, 500)
        },
        deep: true,
      },
    },

    mounted () {
      this.filterOptions.temperature = parseInt(this.$route.query.temperature) || ''
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getLeads () {
        this.loading = true
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          let apiurl = `${this.url}?page=${page}&per_page=${itemsPerPage}`
          if (this.filterOptions.search) {
            apiurl += `&search=${this.filterOptions.search.replace('&', '%26')}`
          }
          if (this.filterOptions.temperature) {
            apiurl += `&temperature=${this.filterOptions.temperature}`
          }
          if (sortBy[0]) {
            const direction = sortDesc[0] ? 'desc' : 'asc'
            apiurl += `&direction=${direction}&sortBy=${sortBy[0]}`
          }
          const response = await axios.get(apiurl)
          this.leads = response.data.data
          this.total = response.data.total
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },

      userOrCompany (userOrCompany) {
        const user = {
          color: 'primary',
          text: 'Personal',
        }
        const company = {
          color: 'success',
          text: 'Commercial',
        }

        return userOrCompany ? user : company
      },
    },
  }
</script>
